import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { useToast } from "../../../../components/toast/use-toast.tsx";
import { VendorSelector } from "../components/vendorSelector";
import { ModelSelector } from "../components/modelSelector";

const AddNewSlider = () => {
  const { user } = useContext(AuthContext);
  const { toast } = useToast();
  const navigate = useNavigate();

  const [vendors, setVendors] = useState([]);
  const [models, setModels] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [sliderImage, setSliderImage] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [modelSlug, setModelSlug] = useState(null);
  const [priorityOrder, setPriorityOrder] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchVendorsWithModels = async () => {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/getVendorsWithModels?token=${user?.token}`,
        {
          method: "GET",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );
      const data = await response.json();
      if (response.ok && data.success) {
        setVendors(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch vendors and models");
      }
    } catch (error) {
      console.error("Error fetching vendors and models:", error);
      toast({
        title: "Error",
        description: "Failed to fetch vendors and models",
        status: "error",
      });
    }
  };

  useEffect(() => {
    fetchVendorsWithModels();
  }, []);

  const handleVendorChange = (vendorId) => {
    setVendorId(vendorId);
    const selectedVendor = vendors.find((vendor) => vendor.vendorId === vendorId);
    setModels(selectedVendor?.models || []);
    setModelSlug(null); // Reset model slug when vendor changes
  };

  const handleModelChange = (modelSlug) => {
    setModelSlug(modelSlug);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSliderImage(file);
      setImagePreview(URL.createObjectURL(file)); // Display image preview
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!sliderImage || !vendorId || !modelSlug || !priorityOrder) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("sliderImage", sliderImage);
    formData.append("vendorId", vendorId);
    formData.append("modelSlug", modelSlug);
    formData.append("priorityOrder", priorityOrder);

    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/addNewSlider?token=${user?.token}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to add new slider");
      }

      toast({
        title: "Slider Added",
        description: "New slider added successfully.",
      });

      navigate("/admin/sliders"); // Redirect back to sliders page
    } catch (error) {
      console.error("Error adding new slider:", error);
      toast({
        title: "Error",
        description: "Failed to add new slider.",
        status: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-3xl font-bold mb-6 text-center">Add New Slider</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex justify-center">
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-full h-60 object-cover rounded-md border"
            />
          ) : (
            <div className="w-full h-60 flex items-center justify-center bg-gray-200 rounded-md border">
              <p className="text-gray-500">No image selected</p>
            </div>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Upload Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            disabled={isSubmitting}
            className="mt-2 block w-full text-sm border-gray-300 rounded-md cursor-pointer focus:ring focus:ring-blue-300"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Vendor</label>
          <VendorSelector
            vendors={vendors}
            selectedVendor={vendorId}
            onSelect={handleVendorChange}
            disabled={isSubmitting}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Model</label>
          <ModelSelector
            models={models}
            selectedModel={modelSlug}
            onSelect={handleModelChange}
            disabled={isSubmitting || !vendorId}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Priority Order</label>
          <input
            type="number"
            value={priorityOrder}
            onChange={(e) => setPriorityOrder(e.target.value)}
            disabled={isSubmitting}
            className="mt-2 block w-full text-sm border-gray-300 rounded-md focus:ring focus:ring-blue-300"
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg text-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Add Slider
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewSlider;
