import React, { useState, useContext, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialog/index.tsx"
import { AuthContext } from '../../../../context/AuthContext'
import { cn } from '../../../../lib/utils.js';


const DeleteModel = ({selectedModelName, onClick}) => {
  const [modelName, setModelName] = useState('');
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(()=>{
    if(modelName.toLowerCase() !== selectedModelName.toLowerCase()){
      setValidated(false);
    }
    else{
      setValidated(true);
    }
  },[modelName])


  return (
    <Dialog open={open} onOpenChange={(val) => {
      setOpen(val);
      if(!val) setModelName("")
    }}>
      <DialogTrigger className='p-2 border border-black rounded-md'>Delete Model</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to delte Model?</DialogTitle>
          <DialogDescription>
            <div>
              <p className='font-semibold text-black border border-black rounded-md p-1 max-w-fit'>Deleted Models cannot be resored.</p>
              <div className='py-4'>
                <p>Please enter Model name and Model Id to confirm</p>
                <div>
                  <label>Model Name:</label>
                  <input
                    type="text"
                    name="modelName"
                    onChange={(e) => setModelName(e.target.value)}
                    value={modelName}
                    placeholder="Model Name"
                    className={`block w-full rounded outline-none border-2 px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                  />
                </div>
                <div className='flex'>
                  <button
                    className={cn('border mt-4 p-2 text-base rounded-md bg-black text-white',
                      !validated ? 'bg-slate-700' : '')}
                    onClick={() => {
                      onClick();
                      setOpen(false);
                    }}
                    disabled={!validated}
                  >
                    Delete Model
                  </button>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteModel
