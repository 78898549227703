import React, { useState } from "react";
import { Button } from "../../../../components/button/index.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/popover/index.tsx";
import { Check, ChevronsUpDown } from "lucide-react";

export const VendorSelector = ({ vendors, selectedVendor, onSelect, disabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between capitalize"
        >
          {selectedVendor
            ? vendors.find((vendor) => vendor.vendorId === selectedVendor)?.vendorName
            : "Select Vendor"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 max-h-[400px] overflow-y-auto">
        <div className="p-2">
          {vendors.map((vendor) => (
            <div
              key={vendor.vendorId}
              className="cursor-pointer flex items-center gap-2 p-2 hover:bg-gray-100"
              onClick={() => {
                onSelect(vendor.vendorId);
                setOpen(false);
              }}
            >
              <Check
                className={`h-4 w-4 ${
                  selectedVendor === vendor.vendorId ? "opacity-100" : "opacity-0"
                }`}
              />
              {vendor.vendorName}
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
