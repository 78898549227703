import React from 'react';
import { Formik, Field, Form } from 'formik';
import { bodyTypesData } from '../../../../constant/data'; 

const EditUsedModelForm = ({ onFormSubmit, initialValues }) => {
  const validateForm = (values) => {
    const errors = {};
    if (!values.model) errors.model = 'Model name is required';
    if (!values.startPrice) errors.startPrice = 'Start price is required';
    if (!values.modelCatId) errors.modelCatId = 'Category is required';
    if (!values.modelType) errors.modelType = 'Model type is required';
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        formData.append('model', values.model);
        formData.append('startPrice', values.startPrice);
        formData.append('modelCatId', values.modelCatId);
        formData.append('modelType', values.modelType);
        formData.append('modelStatus', values.modelStatus);
        formData.append('redirect_url', values.redirect_url);
        if (values.image) {
          formData.append('image', values.image);
        }

        onFormSubmit(formData, resetForm);
        setSubmitting(false);
      }}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form className="flex flex-col gap-4">
          <div>
            <label className="block text-lg">Model Name</label>
            <Field
              name="model"
              type="text"
              className="border p-2 w-full"
            />
            {touched.model && errors.model && (
              <p className="text-red-500">{errors.model}</p>
            )}
          </div>

          <div>
            <label className="block text-lg">Start Price</label>
            <Field
              name="startPrice"
              type="number"
              className="border p-2 w-full"
            />
            {touched.startPrice && errors.startPrice && (
              <p className="text-red-500">{errors.startPrice}</p>
            )}
          </div>

          <div>
            <label className="block text-lg">Model Type</label>
            <Field
              as="select"
              name="modelType"
              className="border p-2 w-full"
            >
              <option value="">Select a model type</option>
              {bodyTypesData.map((type) => (
                <option key={type.id} value={type.name}>
                  {type.name}
                </option>
              ))}
            </Field>
            {touched.modelType && errors.modelType && (
              <p className="text-red-500">{errors.modelType}</p>
            )}
          </div>

          <div>
            <label className="block text-lg">Redirect URL</label>
            <Field
              name="redirect_url"
              type="text"
              className="border p-2 w-full"
            />
            {touched.redirect_url && errors.redirect_url && (
              <p className="text-red-500">{errors.redirect_url}</p>
            )}
          </div>

          <div>
            <label className="block text-lg">Upload Image</label>
            <input
              name="image"
              type="file"
              onChange={(event) => {
                setFieldValue('image', event.currentTarget.files[0]);
              }}
              className="border p-2 w-full"
            />
          </div>

          <button type="submit" className="p-2 bg-blue-500 text-white rounded-md">
            Save Changes
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default EditUsedModelForm;
