import React from 'react'
import { UsedClickTransactionsTable } from '../../../components/dashboard/Table'

const UsedClickVendorTransactions = () => {
  return (
    <div className='sm:px-8'>
      <UsedClickTransactionsTable/>
    </div>
  )
}

export default UsedClickVendorTransactions
