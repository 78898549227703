import React, { useEffect, useState } from 'react';
import EditUsedModelForm from './components/editUsedModelForm.js';
import { useToast } from '../../../components/toast/use-toast.tsx';
import { useParams, useNavigate } from "react-router-dom";
import DeleteModel from './components/deleteModel.js';

const EditUsedModel = () => {
  const { toast } = useToast();
  const { categoryId, companyName } = useParams();
  const [models, setModels] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const navigate = useNavigate();

  const fetchAllModelsForBrand = async () => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/filterUsedModels?modelCatId=${categoryId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          Cookie: 'cross-site-cookie=name',
        }
      });

      const data = await response.json();
      if (response.ok && data.length > 0) {
        setModels(data); // Set the list of models
        const firstModel = data[0]; // Select the first model from the list
        setSelectedModelId(firstModel.modelId); // Set the first modelId as selected
        fetchModelDetails(firstModel.modelId); // Fetch the details of the first model
      } else {
        setInitialValues(null);
        setModels([]);
        setSelectedModelId(null);
        throw new Error('No models found for the selected category.');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'Unable to fetch models for the brand.',
        status: "error",
      });
    }
  };

  const fetchModelDetails = async (modelId) => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/getUsedModelDetails/${modelId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          Cookie: 'cross-site-cookie=name',
        }
      });
      const data = await response.json();
      if (response.ok) {
        setInitialValues(data);
      } else {
        throw new Error(data.message || 'Error fetching model details');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'Unable to fetch model details.',
        status: "error",
      });
    }
  };

  const handleDeleteModel = async () => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/deleteUsedModel/${selectedModelId}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          Cookie: 'cross-site-cookie=name',
        }
      });

      if (response.ok) {
        toast({
          title: "Model Deleted",
          description: "The used model has been successfully deleted.",
        });
        fetchAllModelsForBrand(); // Fetch the models again after deletion
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Error deleting the model');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'Unable to delete model. Please try again.',
        status: "error",
      });
    }
  };

  useEffect(() => {
    fetchAllModelsForBrand();
  }, [categoryId, toast]);

  // Handle model selection change
  const handleModelChange = (event) => {
    const modelId = event.target.value;
    setSelectedModelId(modelId); // Update the selected modelId
    fetchModelDetails(modelId); // Fetch the details of the selected model
  };

  const handleFormSubmit = async (formData, resetForm) => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/editUsedModel/${selectedModelId}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          Cookie: 'cross-site-cookie=name',
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Model Updated",
          description: "The used model has been successfully updated.",
        });
        navigate(`/admin/usedproducts/${categoryId}/${companyName}`);
      } else {
        throw new Error(data.message || 'Error updating the model');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'Unable to update model. Please try again.',
        status: "error",
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className='flex justify-between'>
        <div className='my-2'>
          <button
            type="button"
            onClick={() => navigate(`/admin/usedproducts/${categoryId}/${companyName}/new-used-model`)}
            className='p-2 border rounded-md bg-black text-white mr-4'
          >
            Add new Used Model
          </button>
          {
            initialValues && 
            <DeleteModel
              selectedModelName={initialValues?.model}
              onClick={handleDeleteModel}
            />
          }
        </div>
      </div>
      {models.length > 0 && (
        <div className="flex flex-col mb-4">
          <label className="mb-2 text-lg font-semibold">Select Model to Edit:</label>
          <select
            value={selectedModelId}
            onChange={handleModelChange}
            className="border p-2 rounded-md"
          >
            {models.map((model) => (
              <option key={model.modelId} value={model.modelId}>
                {model.model}
              </option>
            ))}
          </select>
        </div>
      )}

      {initialValues && (
        <EditUsedModelForm
          onFormSubmit={handleFormSubmit}
          initialValues={initialValues}
          categoryId={categoryId}
        />
      )}
    </div>
  );
};

export default EditUsedModel;
