import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';  // To get companyId and companyName from the URL
import { useToast } from '../../../components/toast/use-toast.tsx';
import { AuthContext } from '../../../context/AuthContext.js'; 

const CompanyDashboard = () => {
  const { companyId, companyName } = useParams();  // Get companyId and companyName from the route
  const [clicksData, setClicksData] = useState([]);
  const [testDrivesData, setTestDrivesData] = useState([]);
  const [totalClickData, setTotalClickData] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);  // Default to today's date
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const { user } = useContext(AuthContext);

  // Helper function to format date to "Day Month Year"
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(new Date(date));
  };

  // Fetch clicks transactions with headers (e.g., authorization, token)
  const fetchClickTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getVendorClicksForDate?vendorId=${companyId}&date=${selectedDate}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          token: `${user?.token}`
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setClicksData(responseData.data.clicksPerModel);
        setTotalClickData(responseData.data.totalClicks);
      } else {
        setClicksData([]);
        setTotalClickData(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch test drives with headers (e.g., authorization, token)
  const fetchTestDrives = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getTestDrivesForVendorOnDate?vendorId=${companyId}&date=${selectedDate}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          token: `${user?.token}`
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setTestDrivesData(responseData.data);
      } else {
        setTestDrivesData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch vendor balance with headers (e.g., authorization, token)
  const fetchVendorBalance = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getVendorBalance?vendorId=${companyId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          token: `${user?.token}`
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setBalanceData(responseData.data);
      } else {
        setBalanceData(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch data based on companyId and selected date
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch clicks, test drives, and balance data
        await fetchClickTransactions();
        await fetchTestDrives();
        await fetchVendorBalance();
      } catch (error) {
        toast({ description: 'Error fetching data. Please try again later.' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyId, selectedDate, toast, user]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4 capitalize">{companyName}</h1>
      {/* Date Picker */}
      <div className='flex justify-end'>
        <div className="mb-4">
          <label className="block font-medium mb-1">Select Date:</label>
          <input
            type="date"
            className="border p-2 rounded-md"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </div>
      </div>

      { loading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      ) : (
        <>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className="mb-6 border shadow-md p-4 rounded-md flex-1">
              <h2 className="font-bold text-lg mb-2">Vendor Balance</h2>
              <div>
                {balanceData ? (
                  <p className='text-3xl'>€ {balanceData.wallet_balance}</p>
                ) : (
                  <p>Unable to fetch balance data.</p>
                )}
              </div>
            </div>
            <div className="mb-6 border shadow-md p-4 rounded-md flex-1">
              <h2 className="font-bold text-lg mb-2">Outstanding Balance</h2>
              <div>
                {balanceData ? (
                  <p className='text-3xl'>€ {balanceData.outstanding_balance}</p>
                ) : (
                  <p>Unable to fetch balance data.</p>
                )}
              </div>
            </div>
            <div className="mb-6 border shadow-md p-4 rounded-md flex-1">
              <h2 className="font-bold text-lg mb-2 ">Total Clicks on <span className='font-bold underline'>{formatDate(selectedDate)}</span></h2>
              <div>
                {totalClickData ? (
                  <p className='text-3xl'>{totalClickData}</p>
                ) : (
                  <p className='text-3xl'>0</p>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Click Transactions Section */}
            <div className="border shadow-md p-4 rounded-md">
              <h2 className="text-lg mb-2 font-bold">Click Transactions on <span className='font-bold underline'>{formatDate(selectedDate)}</span></h2>
              <div>
                {clicksData && clicksData.length > 0 ? (
                  <table className="table-auto w-full text-left">
                    <thead>
                      <tr>
                        <th className="border-b py-2">Model Name</th>
                        <th className="border-b py-2">Clicks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clicksData.slice(0, 5).map((click, index) => (
                        <tr key={index}>
                          <td className="border-b py-2">{click.model_name}</td>
                          <td className="border-b py-2">{click.click_count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No click transactions available for this date.</p>
                )}
              </div>
            </div>

            {/* Test Drives Section */}
            <div className="border shadow-md p-4 rounded-md">
              <h2 className="text-lg mb-2 font-bold ">Test Drives on <span className='font-bold underline'>{formatDate(selectedDate)}</span></h2>
              <div>
                {testDrivesData && testDrivesData.length > 0 ? (
                  <table className="table-auto w-full text-left">
                    <thead>
                      <tr>
                        <th className="border-b py-2">Model Name</th>
                        <th className="border-b py-2">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDrivesData.slice(0, 5).map((testDrive, index) => (
                        <tr key={index}>
                          <td className="border-b py-2">{testDrive.model}</td>
                          <td className="border-b py-2">{testDrive.bookingStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No test drives booked for this date.</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyDashboard;
