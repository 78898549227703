import React from "react";


import Dashboard from "./views/admin/dashboard";



import {
  MdHome,
  MdOutlineProductionQuantityLimits

} from "react-icons/md";
import { BiBarChartAlt } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";
import { FaRegNewspaper } from "react-icons/fa";
import Products from "./views/admin/products";
import TestDrive from "./views/admin/testdrive";
import Payment from "./views/admin/payment";
import NewsLetter from "./views/admin/newsletter";
import Transactions from "./views/admin/transactions";
import ClickVendorTransactions from "./views/admin/click-payments";
import { PlusCircleIcon } from "lucide-react";
import NewTransaction from "./views/admin/new-transaction";
import VendorsOrder from "./views/admin/vendors-order";
import BlacklistIPsPage from "./views/admin/blacklisted Ips";
import UsedClickVendorTransactions from "./views/admin/used-click-payments";
import SlidersPage from "./views/admin/sliders";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
    type:"accordian",
  },
  {
    name: "Sliders",
    layout: "/admin",
    path: "sliders",
    icon: <FaRegNewspaper className="h-6 w-6" />,
    component: <SlidersPage />,
    type:"link",
    secondary: true,
  },
  {
    name: "Car Models",
    layout: "/admin",
    path: "products",
    icon: <MdOutlineProductionQuantityLimits className="h-6 w-6" />,
    component: <Products />,
    type:"accordian",
    secondary: true,
  },
  {
    name: "Used Models",
    layout: "/admin",
    path: "usedproducts",
    icon: <MdOutlineProductionQuantityLimits className="h-6 w-6" />,
    type:"accordian",
    secondary: true,
  },
  {
    name: "Vendors Order",
    layout: "/admin",
    path: "vendors-order",
    icon: <MdOutlineProductionQuantityLimits className="h-6 w-6" />,
    component: <VendorsOrder />,
    type:"link",
    secondary: true,
  },
  {
    name: "Test drive Booking",
    layout: "/admin",
    path: "test-drives",
    icon: <BiBarChartAlt className="h-6 w-6" />,
    component: <TestDrive />,
    type:"link",
    secondary: true,
  },
  {
    name: "Payments",
    layout: "/admin",
    path: "payments",
    icon: <TbReportMoney className="h-6 w-6" />,
    component: <Payment />,
    type:"link",
    secondary: true,
  },
  {
    name: "Newsletter",
    layout: "/admin",
    path: "newsletter",
    icon: <FaRegNewspaper className="h-6 w-6" />,
    component: <NewsLetter />,
    type:"link",
    secondary: true,
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "transactions",
    icon: <TbReportMoney className="h-6 w-6" />,
    component: <Transactions />,
    type:"link",
    secondary: true,
  },
  {
    name: "Click Payments",
    layout: "/admin",
    path: "click-payments",
    icon: <TbReportMoney className="h-6 w-6" />,
    component: <ClickVendorTransactions />,
    type:"link",
    secondary: true,
  },
  {
    name: "Used Click Payments",
    layout: "/admin",
    path: "used-click-payments",
    icon: <TbReportMoney className="h-6 w-6" />,
    component: <UsedClickVendorTransactions />,
    type:"link",
    secondary: true,
  },
  {
    name: "New Transaction",
    layout: "/admin",
    path: "new-transaction",
    icon: <PlusCircleIcon className="h-6 w-6" />,
    component: <NewTransaction />,
    type:"link",
    secondary: true,
  },
  {
    name: "Blacklisted Ips",
    layout: "/admin",
    path: "blacklist-vendor-click-ips",
    icon: <PlusCircleIcon className="h-6 w-6" />,
    component: <BlacklistIPsPage />,
    type:"link",
    secondary: true,
  },
];
export default routes;
