/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "../../../components/icons/DashIcon";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./Accordion.tsx";
// chakra imports

export function SidebarLinks(props) {
  const [companiesData, setCompaniesData] = useState([]);
  let location = useLocation();

  const { routes } = props;
// get company names
// if submenu present, then show submenu
// 
  const activeRoute = (routeName) => {
    return location.pathname.endsWith('/'+routeName);
  };

  async function fetchCompanyData() {
    const cardUrl =
      "https://evmainbackend.theevshowroom.com/api/getallmodel";
    try {
      const response = await fetch(cardUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
        },
      });
      
      const dataResponse = await response.json();
      if (dataResponse && dataResponse.status) {
        setCompaniesData(
          dataResponse.data.sort((a, b) => {
            if (a.categoryName.toLowerCase() < b.categoryName.toLowerCase()) {
              return -1;
            }
            if (a.categoryName.toLowerCase() > b.categoryName.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
      }else{
        setCompaniesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        if(
          route.type === 'accordian'
        ){
          return(
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="px-8">
                  <div className="flex justify-center">
                    <span
                      className={`${
                        activeRoute(route.path) === true
                          ? "font-bold text-brand-500 dark:text-white"
                          : "font-medium text-gray-600"
                      }`}
                    >
                      {route.icon ? route.icon : <DashIcon />}{" "}
                    </span>
                    <p
                      className={`leading-1 ml-4 flex ${
                        activeRoute(route.path) === true
                          ? "font-bold text-navy-700 dark:text-white"
                          : "font-medium text-gray-600"
                      }`}
                    >
                      {route.name}
                    </p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <ul className="px-12">
                    {
                      companiesData.map( companyData => {
                        return (
                          <li className="uppercase py-[2px]">
                            <Link
                              key="companyData.categoryName" to={route.layout +"/"+route.path+"/"+companyData.id+"/"+companyData.categoryName}
                              className={`${
                                activeRoute(route.path+"/"+companyData.id+"/"+companyData.categoryName) === true
                                  ? "font-bold dark:text-white"
                                  : ""
                              }`}
                            >
                              {companyData.categoryName}
                            </Link>
                          </li>
                        )
                      } )
                    }
                  </ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )
        }
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-brand-500 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND

  useEffect(()=>{
    fetchCompanyData()
  },[])
  return createLinks(routes);
}

export default SidebarLinks;
