import React, { useState } from "react";
import { Button } from "../../../../components/button/index.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "../../../../components/popover/index.tsx"

import { Check, ChevronsUpDown } from "lucide-react";

export const ModelSelector = ({ models, selectedModel, onSelect, disabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled} asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between capitalize"
        >
          {selectedModel
            ? models.find((model) => model.slug === selectedModel)?.modelName
            : "Select Model"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <div className="p-2">
          {models.map((model) => (
            <div
              key={model.slug}
              className="cursor-pointer flex items-center gap-2 p-2 hover:bg-gray-100"
              onClick={() => {
                onSelect(model.slug);
                setOpen(false);
              }}
            >
              <Check
                className={`h-4 w-4 ${
                  selectedModel === model.slug ? "opacity-100" : "opacity-0"
                }`}
              />
              {model.modelName}
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
