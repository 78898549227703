import React from 'react';
import AddUsedModelForm from './components/addUsedModelForm.js';
import { useToast } from '../../../components/toast/use-toast.tsx';
import {useParams} from "react-router-dom";

const AddUsedModel = () => {
  const { toast } = useToast();
  const { categoryId, companyName } = useParams();

  const handleFormSubmit = async (formData, resetForm) => {
    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/addUsedModel', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          Origin: 'https://theevshowroom.com',
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Model Added",
          description: "The used model has been successfully added.",
        });
        resetForm(); // Reset form after successful submission
      } else {
        throw new Error(data.message || 'Error adding the model');
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || 'Unable to add model. Please try again.',
        status: "error",
      });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Add New Used Model</h2>
      <AddUsedModelForm onFormSubmit={handleFormSubmit} categoryId={categoryId}/>
    </div>
  );
};

export default AddUsedModel;
