export const bodyTypesData = [
  {
    id: 1,
    name: "Hatchback",
  },
  {
    id: 2,
    name: "Crossover",
  },
  {
    id: 3,
    name: "Sedan",
  },
  {
    id: 4,
    name: "Coupe",
  },
  {
    id: 5,
    name: "Coupe SUV",
  },
  {
    id: 6,
    name: "SUV",
  },
  {
    id: 7,
    name: "MPV",
  },
  {
    id: 8,
    name: "Van",
  },
  {
    id: 9,
    name: "Limousine",
  },
  {
    id: 10,
    name: "Kombi",
  }

];